/* ============ donationBanner section1 =================== */

  
  .donationSection2 {
    background-color: #eee;
    padding: 20px;
  }
  
  
  /*  ============= Upcoming festival donation ======== */
  .upcoming_Header h4 {
    font-size: 30px;
    font-family: var(---headingfont);
    font-weight: 700;
    margin-bottom: 30px;
  }
  .upcoming_Header h4 span {
    font-size: 30px;
    font-family: var(---headingfont);
    font-weight: 700;
    color: var(---primaryColor);
    margin-bottom: 30px;
  }
  
  .upcoming-head {
    font-size: 30px;
    font-family: var(---headingfont);
    font-weight: 700;
    margin-bottom: 30px;
    text-align: left;
  }
  
  .upcoming-desc {
    font-size: 16px;
    font-family: var(---parafont);
    margin-bottom: 30px;
    text-align: left;
  }
  
  .upcoming-img {
    width: 100%;
    height: auto;
  }
  .upcoming-img img {
    width: 100%;
    height: 100%;
  }
  
  .donte-title h4 {
    font-size: 25px;
    font-family: var( ---headFont);
    font-weight: 700;
    color: var(---primaryColor);
    margin-bottom: 30px;
    position:relative;
  }
  
  .services table .meals h5 {
    font-family: var(---headingfont);
    font-size:14px;
    text-align: left;
  }
  .meals .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  
  .meals:hover .tooltiptext {
    visibility: visible;
  }
  .Donates-btn a {
    text-decoration: none;
    font-family: var(---headingfont);
    color:var(---seconderyColor);
  }
  .Donates-btn .btn {
    box-shadow: 0 0 7px grey;
    margin: 1px;
    list-style: none;
    border-radius: 5px;
    background-color: var(---primaryColor);
    color:var(---seconderyColor);
  }
  .Donate-amount h2{
    text-align: right;
    font-size:14px;
  }
  .sevaImg
  {
    height:350px;

  }
  .sevaImg img
  {
    width:100%;
    height:100%;
    
  }