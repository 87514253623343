.footer-section {
  background: #eee;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
  padding-left:50px;}

.single-cta i {
  color: rgb(122, 110, 45);
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 48px;
  display:inherit;
  text-align: left;
}

.cta-text h4 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
  font-family: var(---headFont);
}

.cta-text span {
  color: #000;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
  
}


.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 130px;
  height:120px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #000;
  line-height: 28px;
}

.footer-social-icon span {
  color: #000;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family:var(---paraFont);
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #000;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  color: #000;
}

.facebook-bg {
  background: #3b5998;
}

.twitter-bg {
  background: #55acee;
}

.google-bg {
  background: #dd4b39;
}

.footer-widget-heading h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
  font-family: var(---headFont);
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: var(---SecondColor);
}

.footer-widget ul li {
  display: inline-block;
  text-align: left;
  width: 33%;
  margin-bottom: 12px;
  font-family: var(---paraFont);
  font-size: 16px;
}

.footer-widget ul li a:hover {
  color: var(---SecondColor);
}

.footer-widget ul li a {
  color: #000;
  text-transform: capitalize;
  text-decoration: none;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #eee;
  border: 1px solid #000;
  color: #111;
}

.subscribe-form button {
  position: absolute;
  right: 2px;
  background: var(---primecolor);
  padding: 13px 20px;
  border: 2px solid var(---SecondColor);
  bottom: 2px;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.copyright-text p a {
  color: #fff;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: var(---primeColor);
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.bottomNavbar .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
color: #808080 !important;
}

.bottomNavbar .MuiTabs-indicator{
display: none;
}

.bottomNavbar .MuiBox-root{
margin: auto;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root{
box-shadow: unset;
}
@media(max-width:500px)
{
  .footer-content {
    position: relative;
    z-index: 2;
    text-align: left;
  }
  
.footer-cta {
  border-bottom: 1px solid #373636;
  padding-left:10px;}
  .footer-widget
  {
    padding:10px 0;
  }
  .cta-text {
    padding-left: 48px;
    display:inherit;
    text-align: left;
  }
  .single-cta i {
    color: var(---primecolor);
    font-size: 20px;
    float: left;
    margin-top: 8px;
  }
  .footer-widget ul
  {
    margin:0;
    padding:0;
  }
  
.footer-widget ul li a {
  display: inline-block;
  text-align: left;
  width: 100%;
  font-family: var(---paraFont);
  font-weight: 500;
  font-size:16px;
}
  
}