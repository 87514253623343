/* ================================= Home Section ====================================== */
p{
    text-align:justify;
}
.home {
    background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .5)), url(/src/Images/gallery/IMG_7571.JPG);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    position: relative;
    background-position: center;
   
}

.HomeHead {
    padding: 0 50px;
    position: absolute;
    /* top: 20%;
    left: 40%; */
    width: 100%;
    /* height: 100vh; */
    transform: translateY(30%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.HomeHead span {
    font-size: 50px;
    font-weight: 500;
    font-family: var(---btnFont);
    text-transform: capitalize;
    color: #fff;
    /* line-height: 80px; */
    border-bottom-style: double;
    border-bottom-color:var(---secondcolor);
    /* border-right-style: inset;
    border-right-color: var(---secondcolor); */
    border-top-style: double;
    border-top-color: var(---secondcolor);
    line-height:100px;
}

/* .HomeHead h2 {
    font-size: 50px;
    font-weight: 600;
    font-family:var(---headFont);
    text-align: center;
    text-transform: uppercase;
    color: rgb(248, 225, 183);
    
} */
.HomeHead h2 {
    margin-top:30px;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Cinzel', serif;
    text-align: center;
    text-transform: uppercase;
    color:var(---secondcolor) !important;;
    line-height: 70px;
    
}
.viewBtn {
    padding:10px 20px;
      /* border:1px solid var(---secondcolor); */
      color:#fff;
      background:var(---primecolor);
      font-size: 16px;
      /* font-weight: 600; */
      border: none;
      border-radius: 50px;
     
      font-family: var(---btnFont);
  }
.viewBtn1 {
  padding:10px 20px;
    /* border:1px solid var(---secondcolor); */
    color:#fff;
    background:var(---primecolor);
    font-size: 16px;
    /* font-weight: 600; */
    border: none;
    border-radius: 50px;
    margin-top:40px;
    font-family: var(---btnFont);
}
  a{
    text-decoration: none;
  
  }
  p a{color:red;}

  .bannerLogo
  {
    width:200px;
    height:70px;
   overflow:hidden;
  }
  .bannerLogo img  {
    width:100%;
    height:100%;
    transform:scale(1.2,3);
  }
 
@media(max-width:500px) and (min-width:150px) {
    .home {
        background: linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .5)), url(/src/Images/gallery/IMG_7571.JPG);
        background-size: cover;
        background-repeat: no-repeat;
      background-position: center;
        height: 65vh;
        position: relative;

    }
    .HomeHead {
        padding: 0 30px;
        position: absolute;
        /* top: 20%;
        left: 40%; */
        overflow-x:hidden;
        transform: translate(0,0);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .HomeHead span {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        font-family: var(---btnFont);
        text-transform: capitalize;
        color: #fff;
        line-height: 50px;
        border-bottom-style: double;
        border-bottom-color: var(---secondcolor);
        /* border-right-style: inset;
        border-right-color: var(---secondcolor); */
        border-top-style: double;
        border-top-color: var(---secondcolor);
    }
    .HomeHead h2 {
        font-size: 20px;
        font-weight: 600;
        font-family: var(---headFont);
        text-align: center;
        text-transform: uppercase;
        color: rgb(248, 225, 183);
        line-height: 50px;
    }
    .viewBtn1 {
        padding:10px 20px;
          /* border:1px solid var(---secondcolor); */
          color:#fff;
          background:var(---primecolor);
          font-size: 16px;
          /* font-weight: 600; */
          border: none;
          border-radius: 50px;
          margin-top:30px;
          font-family: var(---btnFont);
      }
      .bannerLogo
      {
        width:115px;
        height:60px;
       
      }
      .bannerLogo img
      {
        width:100%;
        height:100%;
        /* transform:scale(1.4,3.5); */
      }
}

/* ============================== services=========================== */

.aboutDesc
{padding: 20px;}
.aboutDesc h2 {
    position:relative;
    font-size: 30px;
    font-weight: 600;
    font-family: var(---headFont);
    text-align: center;
    text-transform: uppercase;
    color:var(---primecolor);
    line-height: 100px;
}
.aboutDesc h2::before {
    position: absolute;
    top:20px;
    left: 41%;
    transform: rotate(180deg);
    content: url('../Images/seperator-removebg-preview.png');
    /* background-color: rgb(116, 11, 11); */
    z-index: 10;
    /* transform: translateX(-127%); */
}
.krishnaImg1
{
    position:relative;
    box-shadow: 0 14px 28px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22);
    margin-top:10px;
    /* width:300px; */
    height:390px;
    border-radius: 10px;
}
.krishnaImg1 img
{
    width:100%;
    height:100%;
}
@media(max-width:910px) 
{
    .krishnaImg1
{
    position:relative;
    box-shadow: 0 14px 28px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22);
    margin-top:10px;
    /* width:300px; */
    height:auto;
    border-radius: 10px;
}
.krishnaImg1 img
{
    width:100%;
    height:100%;
}
}
.intro-right h4{ 
    margin-top: 17px;
    font-size: 1rem;
    font-weight: 500;
    color:#000;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 65px  !important;
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}

@media(max-width:500px)
{

.aboutDesc
{padding: 20px;}
.aboutDesc h2 {
    position:relative;
    font-size: 22px;
    font-weight: 600;
    font-family: var(---headFont);
    text-align: center;
    text-transform: uppercase;
    color:var(---primecolor);
    line-height: 40px;
    padding-bottom:30px;
    
}
.aboutDesc h2::before {
    position: absolute;
    top: 0;
    bottom:0;
    left: 0;
    right:0;
    transform: rotate(180deg);
    content: url('../Images/seperator-removebg-preview.png');
    /* background-color: rgb(116, 11, 11); */
    z-index: 10;
    /* transform: translateX(-127%); */
}
}

/* .aboutDesc h2::after {
    position: absolute;
    top: 30px;
    width: 5rem;
    left: 20px;
    height: 2px;
    content: "";
    background-color: var(---secondcolor);
    z-index: 10;
    transform: translateX(-127%);
} */
.aboutDesc span
{
    font-family:var(---paraFont);
}
.service {
    /* background: #eee; */
    padding: 50px 0;
}

.servicecontain {
    position: relative;
    height: 500px;
    padding: 30px;
}

.servicecontain img {
    width: 100%;
    height: 100%;
}

.serviceDetails {
    padding: 20px 0;
    margin:0 50px;
    background:whitesmoke;
}


/* .serviceDetails {
    position: absolute;
    background:var(---secondcolor);
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    padding: 40px;
} */

.serviceDetails li {
    list-style: none;
    line-height:0;
    text-align:left;
    padding:10px 0;
}

.serviceDetails i {
    font-size: 1.5rem;
    color: var(---primecolor);
    padding: 10px;
    
}

.serviceDetails span {
   
    margin-left: 30px;
    font-size: 14px;
   
    color:gray;
    text-align: center;
    text-transform: uppercase;
    
}

@media(max-width:997px) and (min-width:767px)
{
    
.serviceDetails {
    padding: 20px 0;
    margin:0 10px;
    background:whitesmoke;
}
    .serviceDetails span {
   
        margin-left: 10px;
        font-size: 12px;
       
        color:gray;
        text-align: center;
        text-transform: uppercase;
        
    }
}
@media(max-width:766px)  and (min-width:200px){
    .serviceDetails {
        padding: 10px;
        
        margin:0;
        background:#eee;
    }
    .serviceDetails i {
        font-size: 2rem;
        color:var(---primecolor);
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .serviceDetails span {
        margin-top: 15px;
        margin-left: 10px;
        font-size: .7rem;
        color:grey;
        line-height:20px;
        text-align: center;
        text-transform: uppercase;
    }
    .servicecontain {
        position: relative;
        height: 600px;
        padding: 0;
    }
}


/* ----------------------------banquets------------------------ */
.city-img
{
    position: relative;
    box-shadow: 0 14px 28px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22);
    border-radius:10px;
    margin:20px 0;
    height:250px;
}
.city-img1
{
    position: relative;
    box-shadow: 0 14px 28px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22);
    border-radius:10px;
    margin:20px 0;
    height:530px;
}
.city-img1 img
{
    width:100%;
    height:100%;
}
.city-img img
{
    width:100%;
    height:100%;
}

/* .container .city-dtl::before{
    transform: scale(1,0); 
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
}
.container .city-dtl::after{
    transform: scale(0,1);
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}
.container .city-dtl:hover::before,.container .city-dtl:hover::after{
    transform: scale(1,1);
} */

.city-dtl h2{
    color:#000;
    font-size:14px;
font-weight:600;
    font-family: var(---headFont);
}


.city-dtl h5{
    color:var(---primecolor);
font-size:16px;
/* font-weight:600; */

    font-family: var(---paraFont);
}

.why-Choose
{
    background:rgb(253, 253, 253);
}
.plan-discription
{
    text-align:left;
    padding:20px;

}
@media(max-width:910px)
{
    .plan-discription
{
    text-align:left;
    padding:5px;

}

}

.plan-discription h3{
    font-size: 30px;
    font-weight: 600;
    font-family: var(---headFont);
    /* text-align: center; */
    text-transform: capitalize;
    color:#111;
    line-height: 30px;
}
@media(max-width:500px)
{
    .plan-discription h3{
        font-size: 20px;
        font-weight: 600;
        font-family: var(---headFont);
        /* text-align: center; */
        text-transform: capitalize;
        color:#111;
        line-height: 30px;
    }
}
.section-choose 
{
    text-align:left;
}

.section-choose h4
{
    font-size: 22px;
    font-weight: 600;
    font-family: var(---headFont);
    /* text-align: center; */
    text-transform:capitalize;
    color:#000;
    line-height: 30px;
}
.section-choose p
{
   text-align: justify;
}
.section-choose li 
{
list-style: none;
}
.section-choose li i
{
    color:red;
    font-size:.8rem;
 
}
.widget-icon 
{
   width:50px;
   height:50px;
}
.widget-icon  img
{
   width:100%;
   height:100%;
}
.widget-icon i:after
{position: absolute;
    top: 5px;
    width: 2px;
    left: 70px;
    height: 50px;
    content: "";
    background-color: #eee;
    z-index: 10;
    transform: translateX(-127%);
}
.plan-start
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:80px;
}
.plan-start h3
{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:var(---headFont);
font-size: 50px;
font-weight: 600;
color:var(---primecolor);
}
@media(max-width:500px)

{
    .section-choose h4
{
    font-size: 20px;
    font-weight: 600;
    font-family: var(---headFont);
    /* text-align: center; */
    text-transform:capitalize;
    color:#000;
    line-height: 30px;
}
    .widget-icon
    {
        text-align:left;
    }
    .widget-icon i
{
    font-size: 40px;
    position:relative;
    color:var(---primecolor);
}
.widget-icon i:after
{position: absolute;
    top: -10px;
    width: 2px;
    left: 70px;
    height: 50px;
    content: "";
    background-color: #eee;
    z-index: 10;
    transform: translateX(-127%);
}
}

.serviceList .col-md-3 h1 {
    padding: 15px;
    font-family: var(---headfont);
    text-align: center;
    font-size: 30px;
}

.serviceList .col-md-3 a {
    text-align: center;
    justify-content: center;
    display: flex;
    color: #111;
    text-decoration: none;
    font-family: var(---parafont);
}

.servList {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    border-left: 2px solid goldenrod;
    border-top: 1px solid #000;
    border-bottom: 2px solid goldenrod;
    border-right: 1px solid #000;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 180px;
    min-height: 100%;

}

.servList h2 {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: var(---paraFont);
    color:var(---secondcolor)

}

.servList:hover {
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 3px 5px 14px #80808080;
}

.serviceList h6 {
    position: relative;
    color: #000;
    font-size: .8rem;
    text-transform: capitalize;
    text-align: center;
    font-family: var(---parafont);
}

.serviceList h6::after {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 30px;
    background-color: goldenrod;
    height: 2px;
}

.serviceList .col-md-3 p {
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: var(---parafont);
}

.serviceIcon {
    width: 50px;
    height:50px;
    margin: 15px 0 30px 0;

}

.serviceIcon img {
    width: 100%;
    height: 100%;

}

.homeSection .section3 {
    padding: 0px;
    background: #eee;
}
@media(max-width:500px)
{
    .servList {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 10px;margin-top:20px;
        border-left: 2px solid goldenrod;
        border-top: 1px solid #000;
        border-bottom: 2px solid goldenrod;
        border-right: 1px solid #000;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 180px;
        min-height: 100%;
    
    }
    .servList h2 {
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        font-family: var(---paraFont);
        color:var(---secondcolor)
    
    }
}
@media (max-width: 1045px)  {
    .section3 {
        padding: 0px;
    }

    .serviceList {
        padding: 0px;
    }

    .serviceList .col-md-3 .servList h2 {
        font-size: 16px;
    }

    .serviceList .col-md-3 .servList h6 {
        font-size: 12px;
    }

    .serviceList .col-md-3 .servList p {
        font-size: 15px;
    }
}
@media(max-width:500px)
{
    .serviceList {
        padding:0 0 30px 0;
    }

}
/* -------------------------connect------------------- */
.offerSection {
    height: auto;
    background: linear-gradient(to right, rgba(12, 12, 12, 0.7), rgba(0, 0, 0, 0.7)), url('/src/Images/banner1.jpg');
    background-size: cover;
    background-position: 100% 100%;
}

.offerTitle {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.offerTitle span {
    color: var(---secondcolor);
    font-size: 1.2rem;
    font-weight: 600;
    font-family: var(---cursiveFornt);
    text-transform: capitalize;
    text-align: center;
    line-height: 100px;
}

.offerTitle h2 {
    color: #fff !important;
    font-size: 50px;
    font-weight: 600;
    font-family: var(---headFont);
    text-transform: capitalize;
    text-align: center;
    line-height: 80px;
}

.offerTitle p {
    color: #fff;
    font-size: 1rem;
    font-family: var(---parafont);
    text-transform: capitalize;
    line-height: 40px;
}

.connectBtn {
    /* position: absolute; */
    width: 150px;
    height: 50px;
    border-radius: 5px;
    background: none;
    /* border: none; */
    border:1px solid var(---secondcolor);
    /* margin: 10px; */
    color: #fff;
    font-family:var(---btnFont);
}

@media(max-width:500px) {
    .offerSection {
        height: auto;
        background: linear-gradient(to right, rgba(12, 12, 12, 0.7), rgba(0, 0, 0, 0.7)), url('/src/Images/banner1.jpg');
        background-size: cover;
        background-position: 100% 100%;
    }
    .offerTitle {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        text-align: center;
    }
    .offerTitle span {
        color: var(---secondcolor);
        font-size: 1.2rem;
        font-weight: 600;
        font-family: var(---cursiveFornt);
        text-transform: capitalize;
        text-align: center;
        line-height: 50px;
    }
    .offerTitle h2 {
        color: #fff;
        font-size: 1.3rem;
        font-weight: 600;
        font-family: var(---headFont);
        text-transform: capitalize;
        text-align: center;
        line-height: 50px;
    }
    .offerTitle p {
        color: #fff;
        text-align: center;
        font-size: .8rem;
        font-family: var(---poppinFont);
        text-transform: capitalize;
        line-height: 40px;
    }
    .connectBtn {
        /* position: absolute; */
        width: 150px;
        height: 50px;
        border-radius: 5px;
        border: none;
        border:1px solid var(---secondcolor);
        /* margin: 10px; */
        color: #fff;
    }
}
.side-logo
{
    height:350px;
}
.side-logo img
{
    width:100%;
    height:100%;
}

.container .card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* width: 350px; */
    max-width: 100%;
    height: 300px;
    /* background: white; */
    border-radius: 10px;
    /* transition: 0.5s; */
    box-shadow: 0 0 3px #aaa8a8;
}
@media(max-width:910px) and (min-width:500px)
{
    .container .card {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        /* width: 350px; */
        max-width: 100%;
        height: 300px;
        /* background: white; */
        border-radius: 10px;
      
        box-shadow: 0 0 3px #808080;
        margin-top:20px;
    }
    
}
.container .card:hover {
    
    
    background:#fff;
}

.container .card .img-box {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #333;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.5s;
}

.container .card:hover .img-box {
    top: -120px;
    scale: 0.9;
    height:240px;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
}


.container .card .img-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container .card .content {
    position: absolute;
    top: 300px;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
    display: none;
}

.container .card:hover .content {
    top: 120px;
    height: 250px;
    display:block;
}

.container .card .content h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
}

.container .card .content p {
    color: #333;
}
@media(max-width:500px)
{.container .card .content h2 {
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

    .container .card:hover .img-box {
        top: -130px;
        scale: 0.9;
        height:240px;
        box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
    }
    
    .container .card:hover .content {
        top: 115px;
        height: 250px;
        display:block;
        padding:0 10px;
    }   
}

.container .card .content a {
    position: relative;
   
    display: inline-block;
    /* padding: 12px 25px; */
    text-decoration: none;
    background: var(--clr);
    color: #000;
    font-weight: 500;
}

.container .card .content a:hover {
    opacity: 0.8;
}

@media (max-width: 480px) {
    .container .card {
        width: 100%;
        border-radius: 10px;
        margin-bottom:25px;
    }

    .container .card .img-box {
        width: 100%;
        border-radius: 10px;
    }

    .container .card .content p {
        font-size: 0.8rem;
    }

    .container .card .content a {
        font-size: 0.9rem;
    }
}

.read-or-hide {
    color: red;
    margin-left: 10px;
    font-weight:bold;
  }
  