
.contact-page-section
{
    background:linear-gradient(to right, rgb(0, 0, 0, .2), rgb(0, 0, 0, .5)),url(../Images/Temple/2D0A2714.JPG);
    background-size:100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.contact-page-section {
    position: relative;
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .contact-page-section .inner-container {
    position: relative;
    z-index: 1;
    background-color: #b80000;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
  }
  .contact-page-section .form-column {
    position: relative;
    padding: 0 0 0 15px;
  }
  .contact-page-section .form-column .inner-column {
    position: relative;
    padding: 60px 45px 30px;
    background-color: #fff;
  }
  .contact-page-section .info-column {
    position: relative;
    background:#eee;
  }
  .contact-page-section .info-column .inner-column {
    position: relative;
    padding: 60px 10px;
  }
  .contact-page-section .info-column h2 {
    position: relative;
    font-family:var(---headFont);
    color: #000;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 45px;
  }
  .contact-page-section .info-column .list-info {
    position: relative;
    font-family:var(---paraFont);
    margin-bottom: 60px;
    margin:0;
    padding:0;
  }
  .contact-page-section .info-column .list-info li {
    position: relative;
    margin-bottom: 25px;
    font-size: 18px;
    text-align:left;
    color:grey;
    line-height: 1.8em;
    padding-left: 45px;
    list-style: none;
  }
  .contact-page-section .info-column .list-info li:last-child {
    margin-bottom: 0;
  }
  .contact-page-section .info-column .list-info li i {
    position: absolute;
    left: 0;
    top: 8px;
    color:rgb(122, 110, 45);
    font-size: 30px;
  }
  .contact-form {
    position: relative;
  }
  .contact-form .form-group {
    position: relative;
    margin-bottom: 20px;
  }
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form input[type=""],
  .contact-form select[type=""],
  .contact-form textarea ,

  .contact-form select,input{
    position: relative;
    display: block;
    width: 100%;
    height: 60px;
    color: #222;
    font-size: 14px;
    line-height: 38px;
    padding: 10px 30px;
    border: 1px solid #ddd;
    background-color: #fff;
    transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
  }
  .contact-form input[type="text"]:focus,
  .contact-form input[type="email"]:focus,
  .contact-form textarea:focus {
    border-color: #00b8ca;
  }
  .contact-form textarea {
    height: 250px;
    resize: none;
  }
  .contact-form .theme-btn {
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
    text-transform: capitalize;
    padding: 16px 39px;
    /* border: 2px solid pink; */
    font-family: Arimo, sans-serif;
    border: 1px solid #000;
    display: inline-block;
    position: relative;
    line-height: 24px;
    cursor: pointer;
    background:none;
    color: #000;
  }
  .contact-form .theme-btn:hover {
    color: rgb(236, 204, 156);
    border-color: rgb(236, 204, 156);
    background: 0 0;
  }
  .contact-form input.error,
  .contact-form select.error,
  .contact-form textarea.error {
    border-color: red !important;
  }
  .contact-form label.error {
    display: block;
    line-height: 24px;
    padding: 5px 0 0;
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    color: red;
    font-weight: 500;
  }
  .social-icon-four {
    position: relative;
  }
  .social-icon-four li {
    position: relative;
    margin-right: 18px;
    display: inline-block;
  }
  .social-icon-four li.follow {
    color: #000;
    font-weight: 600;
    font-size: 24px;
    display: block;
    margin-bottom: 20px;
    font-family:var(---headFont);
  }
  .social-icon-four .fb {
    width:40px;
    height:40px;
    border-radius:5px;
    position: relative;
    font-size: 20px;
    padding:5px;
     background-color: #3b5998;;
    color: var(---primecolor);
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .social-icon-four .insta{
    width:40px;
    height:40px;
    border-radius:5px;
    position: relative;
    font-size: 20px;
    padding:5px;
    background-color: #bc2a8d;
    color: var(---primecolor);
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .social-icon-four .google{
    width:40px;
    height:40px;
    border-radius:5px;
    position: relative;
    font-size: 20px;
    padding:5px;
    background-color: #dd4b39;
    color: var(---primecolor);
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .social-icon-four li a:hover {
    color: #222;
  }
  @media(max-width:500px)
  {
    .contact-page-section .form-column {
      position: relative;
      padding: 0 15px;
    }
    .contact-page-section {
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .contact-page-section .form-column .inner-column {
      position: relative;
      padding:10px;
      background-color: #fff;
    }
  }
  .sec-title {
    position: relative;
    padding-bottom: 40px;
  }
  .sec-title .title {
    position: relative;
    color: #fff;
    font-size: 25px;
    font-family: var(---paraFont);
    font-weight: 800;
    padding-right: 50px;
    margin-bottom: 15px;
    display: inline-block;
    text-transform: capitalize;
  }
  .sec-title .title:before {
    position: absolute;
    content: "";
    right: 0;
    bottom: 7px;
    width: 40px;
    height: 1px;
    background-color: #bbb;
  }
  .sec-title h2 {
    position: relative;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.5em;
    display: block;
  }
  .sec-title.light h2 {
    color: #fff;
  }

  .map-box iframe {
    border: 0;
    width: 100%;
    height: 350px;
  }
  
  .contact-form form .form-group {
    margin-bottom: 25px;
  }
  
  .map-box {
    overflow: hidden;
  }
  .redColor
  {
    color:red;
    font-size:.9rem;
    margin-top:2px;
  }