/*---------------------
  Intro section
-----------------------*/
.section-title
{padding:0 20px 10px 20px;
    text-align: left;}
.section-title h2 {
    position:relative;
    font-size: 30px;
    font-weight: 600;
    font-family:var(---headFont);
    text-align: left;
    text-transform: uppercase;
    color:var(---primecolor);
    line-height: 100px;
}
.section-title h2::before {
    position: absolute;
    top: 20%;
    left: 3%;
    transform: rotate(180deg);
    content: url('../Images/seperator-removebg-preview.png');
    /* background-color: rgb(116, 11, 11); */
    z-index: 10;
    /* transform: translateX(-127%); */
}
.section-title p{
    overflow-y:scroll;
    height:300px;
}
.primary-btn {
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	height:55px;
	padding: 10px;
	margin-top:10px;
	color: #ffffff;
	background: #cd5f2d;
		letter-spacing: 0.5px;
}
.intro-text {
	/* border: 15px solid #F9AD81; */
	border: 10px double  #cd5f2d;
	
	padding-top: 10px 50px;
	
}

/* .intro-right {
    
	padding-top: 168px;
} */

.intro-right p {
	margin-bottom: 31px;
}
/* @media(max-width:1192px)
{
	.intro-right {
		padding-top: 215px;
	}
} */
.krishnaImg
{
    width:300px;
    height:200px;
}
.krishnaImg img
{
    width:100%;
    height:100%;
}

@media(max-width:990px)
{
	.intro-right {
		padding-top: 18px;
	}
}
@media(max-width:500px)
{
    .krishnaImg
{
    width:100%;
    height:200px;
}
.krishnaImg img
{
    width:100%;
    height:100%;
}
    .krishnaImg1
    {
        box-shadow: 0 14px 28px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22);
        margin-top:5px;
        /* width:300px; */
        height:50vh;
        border-radius: 10px;
    }
    .krishnaImg1 img
    {
        width:100%;
        height:100%;
    }
    .section-title
{padding: 10px;
    text-align: left;}
.section-title h2 {
    position:relative;
    font-size: 20px;
    font-weight: 600;
    font-family: var(---headFont);
    text-align: left;
    text-transform: uppercase;
    color:var(---primecolor);
    line-height: 100px;
}
.section-title h2::before {
    position: absolute;
    top: 0;
    left: -15px;
    bottom:0;
   text-align: left;
    transform: rotate(180deg);
    content: url('../Images/seperator-removebg-preview.png');
    /* background-color: rgb(116, 11, 11); */
    z-index: 10;
    padding-bottom:30px;
    /* transform: translateX(-127%); */
}
    .intro-text {
        /* border: 15px solid #F9AD81; */
        border: 10px double  #cd5f2d;
        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 5px;
    }
	.intro-right {
		padding-top: 18px;
	}
}
/*--------------------------------------------------*/
				/* 9 About Us Content*/
/*--------------------------------------------------*/


#whatWeDid .tab_menu .row {
    height: 390px;
    background: url(../Images/gallery/IMG_7929.JPG) no-repeat scroll center 0;
    background-size: cover;
    
    position: relative
}

#whatWeDid .tab_menu .row .nav {
    position: absolute;
    /* right: 15px; */
    top: calc(50% - 85px);
    display: flex;
    justify-content:left;
    align-items: left;
    flex-direction: column;
    right:10px;
    
}

#whatWeDid .tab_menu .row .nav li + li {
    margin-top: 3px
}

#whatWeDid .tab_menu .row .nav li a {
    display: inline-block;
    background: #dcdcdc;
    color: #FFF;
    font-size: 24px;
    font-family: 'PT Sans', sans-serif;;
    line-height: 40px;
    padding: 0 35px;
    min-width: 125px
}

#whatWeDid .tab_menu .row .nav li.active a {
    background:goldenrod;
}

#whatWeDid .tab-contents .tab-content {
    padding-left: 5px;
    text-align:left;
}

#whatWeDid .tab-contents .tab-content .tab-pane h3 {
    font-family: var(---headFont);
    text-transform: uppercase;
    color:#000;
    font-weight:600;
    font-size:22px;
    
}
#whatWeDid .tab-contents .tab-content .tab-pane h4 {
    font-family: var(---paraFont);
   font-size:20px;
   color:var(---primecolor);
   font-weight:600;
    margin: 35px 0 30px;
}
#whatWeDid .tab-contents .tab-content .tab-pane p {
    margin-bottom: 20px;
   
    color: #777;
    line-height: 24px
}

#hww h3 {
    font-family: var(---headFont);
        color:#000;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px
}

#hww h5 {
    font-size: 22px;
    font-weight:600;
    font-family: var(---paraFont);
    color: #000;
    margin-bottom: 20px;
    text-transform: capitalize;
}

#hww  h5 span {
    color:#000;
    margin-right: 5px;
    width:20px;
    height:20px;
    background:red;
    padding:10px;
    color:#fff;
    border-radius: 50%;


}

#hww .col-sm-4 p {
    font-family: 'Questrial', sans-serif;
    line-height: 24px;
    color: #777;
    font-size: 15px
}
.audit 
{border-right:1px solid var(---secondcolor);
    border-bottom: 1px solid var(---primecolor);
    padding:10px;
    min-height:100%;
}
@media(max-width:500px)
{
    .audit 
{border-right:1px solid var(---secondcolor);
    border-bottom: 1px solid var(---primecolor);
    padding:15px 10px;
    margin-bottom:20px;
}
#hww h5 {
    font-size: 18px;
    font-weight:600;
    font-family: var(---paraFont);
    color: #000;
    margin-bottom: 20px;
    text-transform: uppercase
}

#whatWeDid .tab-contents .tab-content {
    padding-left: 0;
    text-align:left;
}
}
 .dwarimg {
    height: 420px !important;
    background: url("../Images/Mahadwara.JPG") no-repeat scroll center 0 !important;
    background-size:contain;
    background-position: center center;
    position: relative;
}