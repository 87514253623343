.cardImg
{
	position:relative;
	width:100%;
	height:350px;
	border-radius: 10px;
	box-shadow: 0px 0px 5px gray;
	/* border:1px solid #000; */
}
.cardImg img
{
	width:100%;
	height:100%;
	border-radius: 10px;

	/* border:1px solid #000; */
}
