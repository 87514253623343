
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,600;1,700&family=Lobster&family=Playball&family=Playfair+Display:ital,wght@1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Cormorant+Garamond:ital,wght@1,600;1,700&family=Cormorant:wght@300&family=Lobster&family=Playball&family=Playfair+Display:ital,wght@1,900&family=Prata&family=Roboto+Mono:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Cormorant+Garamond:ital,wght@1,600;1,700&family=Cormorant:wght@300&family=Lobster&family=MonteCarlo&family=Montserrat:ital,wght@0,100;1,400;1,800&family=Philosopher&family=Playball&family=Playfair+Display:ital,wght@1,900&family=Prata&family=Rajdhani:wght@700&family=Roboto+Mono:wght@100;700&family=Roboto+Slab&family=Roboto:wght@700&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Finlandica:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Beau+Rivage&family=Cardo:ital@1&family=Cinzel+Decorative&family=EB+Garamond&family=Fira+Sans:wght@300&family=Italianno&family=Josefin+Sans:wght@100;200;400&family=Playfair+Display+SC:ital@1&family=Roboto&family=Slabo+27px&family=Tangerine:wght@700&family=Ysabeau+SC&display=swap');
:root {
    ---headFont: 'Roboto', sans-serif;
    ---paraFont: 'Roboto Condensed', sans-serif;
    ---btnFont: 'Roboto Slab', serif;
    ---primecolor: rgb(227, 18, 18) ;
    ---secondcolor:#fff974;
}


/* body {
    background: url('img/wallpaper.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
} */

section {
    overflow-x: hidden;
    padding: 30px 0;
}
@media(max-width:500px)
{
    section {
        overflow-x: hidden;
        padding: 30px 0;
    }
}
.sectionTitle {
    padding: 20px 40px;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; */
}

.sectionTitle span {
    font-size: 1.5rem;
    text-transform: capitalize;
    font-family: var(---paraFont);
    color: #000;
    line-height: 50px;
}

.sectionTitle h2 {
    position: relative;
    font-size: 40px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: var(---headFont);
    color: var(---primecolor);
    line-height: 70px;
}

.sectionTitle h2::before {
    position: absolute;
    top: -50px;
    left: -70px;
    content: url('../Images/seperator-removebg-preview.png');
    /* background-color: rgb(116, 11, 11); */
    z-index: 10;
    /* transform: translateX(-127%); */
}

.sectionTitle h2::after {
    position: absolute;
    top: 30px;
    width: 5rem;
    left: 20px;
    height: 2px;
    content: "";
    background-color: var(---secondcolor);
    z-index: 10;
    transform: translateX(-127%);
}

.sectionTitle p {
    font-size: 1rem;
    font-family: var(---paraFont);
    color: #000;
    /* text-align: center; */
}

@media(max-width:500px) {
    section {
    overflow-x: hidden;
    padding: 20px 0;
}


    .sectionTitle {
        padding: 30px 20px;
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center; */
    }
    .sectionTitle span {
        font-size: 1.5rem;
        text-transform: capitalize;
        font-family: var(---paraFont);
        color: #000;
        line-height: 50px;
    }
    .sectionTitle h2 {
        position: relative;
        font-size: 40px;
        font-weight: 500;
        text-transform: uppercase;
        font-family: var(---headFont);
        color: var(---primecolor);
        line-height: 70px;
    }
    .sectionTitle h2::before {
        position: absolute;
        top: -50px;
        left: -40px;
        /* content: url('img/pngtree-soft-pink-and-lime-wedding-invitation-decoration-png-image_2491609-removebg-preview-removebg-preview.png'); */
        /* background-color: rgb(116, 11, 11); */
        z-index: 10;
        /* transform: translateX(-127%); */
    }
    .sectionTitle h2::after {
        position: absolute;
        top: 30px;
        width: 5rem;
        left: 20px;
        height: 2px;
        content: url("../Images/seperator-removebg-preview.png");
        background-color: rgb(116, 11, 11);
        z-index: 10;
        transform: translateX(-127%);
    }
    .sectionTitle p {
        font-size: 1rem;
        font-family: var(---paraFont);
        color: #000;
        /* text-align: center; */
    }
}

/* ======================== Navbar ======================== */

.navbar {
    background:#eee;
}





.nav-item {
    padding: 0 10px 0 10px;
}

/* .navbar-brand {
    margin-top: 10px;
    padding-top: 10px;
    width: 50px;
    height: 70px;
}

.navbar-brand img {
    width: 100%;
    height: 100%;
    transform: scale(1.7, 1.5);
} */

/* .navbar-toggler {
    background: #fff;
} */

.navbar-toggler-icon {
    background: #000;
    width: 2.5rem;
    height: 2px;
    position: relative;
    /* transition: transform 350ms ease-in-out, opacity 200ms linear; */
}

.navbar-toggler-icon:before {
    top: -8px;
    left: 0;
    position: absolute;
    content: '';
    background: #000;
    width: 2.5rem;
    height: 2px;
    /* transition: transform 350ms ease-in-out, opacity 200ms linear; */
}

.navbar-toggler-icon:after {
    top: 7px;
    left: 0;
    position: absolute;
    content: '';
    background: #000;
    width: 2.5rem;
    height: 2px;
    /* transition: transform 350ms ease-in-out, opacity 200ms linear; */
}

.callLink {
    width: 130px;
    height: 40px;
     border:1px solid var(---secondcolor);
    padding: 10px;
    border-radius: 50px;

    /* box-shadow: 0 0 2px 2px #808080; */
}

.callLink a {
    text-decoration: none;
}

.callLink i {
    color: var(---secondcolor);
}

.callLink span {
   font-family:var(---headFont);
    font-size: 16px;
    font-weight: 600;
    color:#111;
    text-transform: uppercase;
}
.navbar{
    /* background-color: rgb(237 237 237); */
    /* position: absolute; */
    width: 100%;
}
.nav-link
{
 margin:5px;
    font-size:16px;
    font-weight:600;
    text-transform: uppercase;
	font-family:var(---headFont);
    color:#000;
}
.nav-link:hover {
    color:var(---primecolor);
    font-size:16px;
    font-weight:600;
    text-transform: uppercase;
	font-family:var(---headFont);
}
.navbar-collapse {
    justify-content: center;
}
.navbar-brand {
	height: 90px;
    width: 70px;
	
}
.navbar-brand img{
    height: 100%;
    width: 100%;
	transform:scale(1.2,1.3);
}

.navbar-nav{
	padding:0px 10px;
    width: 100%;
    justify-content:center;
}

/* .navbar-nav li{

} */
/* .navbar-nav li a{
    list-style: none;
    text-decoration: none;
    margin:5px 20px;
    color: #b80000;    
    font-family:var(---headFont);
    font-weight: 600;
    font-size: 18px;
	text-transform: uppercase;
} */
.navbar-toggler {
    border-color: rgb(247, 194, 146);
}
 .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(9,55,244, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
