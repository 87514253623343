.aboutInfoSection {
  
    background:#000;
    /* background: linear-gradient(to right, rgb(0, 0, 0, .5), rgb(0, 0, 0, .5)),url('../Images/Temple/2D0A2714.JPG'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
    display:flex;
    justify-content: start;
    align-items: center;
    height: 25vh;
}
.aboutHomeHead {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 20px 0;
}

.aboutHomeHead span {
    font-size: 30px;
    font-weight: 500;
    font-family: var(---paraFont);
    text-transform: capitalize;
    color: #fff;
}

.aboutHomeHead h2 {
    text-align: left;
    font-size: 50px;
    font-weight: 800;
    font-family:var(---headFont);
    text-transform: capitalize;
    color: #fff !important;
    border-bottom: 1px dashed #eee;
    
}
@media(max-width:500px)
{
    .aboutInfoSection {
        padding-bottom: 20px;
        background: #000;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-attachment: fixed;
        display:flex;
        justify-content: start;
        align-items: center;
        height: 20vh;
    }
    .aboutHomeHead h2 {
        text-align: left;
        font-size: 30px;
        font-weight: 500;
        font-family:var(---headFont);
        text-transform: capitalize;
        color: #fff !important;
        border-bottom: 1px dashed #eee;
        
    }
}

/* .aboutHomeHead h2:before {
    position: absolute;
    width: 6rem;
    left: 34%;
    height: 3px;
    content: "";
    background-color: #fff;
    z-index: 4;
}

.aboutHomeHead h2:after {
    position: absolute;
    width: 5rem;
    left: 65%;
    height: 3px;
    content: "";
    background-color: #fff;
    z-index: 4;
    transform: translateX(-127%);
} */

@media(max-width:500px) {
    .aboutHomeHead h2 {
        text-align: center;
        font-size: 20px;
        font-weight: 800;
        font-family:var(---headFont);
        text-transform: capitalize;
        color: var(---secondcolor);
    }
}
.serviceSection
{
    background: whitesmoke;
    padding:50px 0;
}
/*----------------------------------------*/
/*  6. Package Area
/*----------------------------------------*/
.package-area 
{
    padding:50px 0;
}
.package-img{margin-top: -40px}
.package-img.two{
height:300px;}
.package-img.two img{width:100%;
    height:100%;}

    .package-img.three{
        position:relative;
        height:350px;
    border-radius:5px;
border:2px solid #eee;}
    .package-img.three img{width:100%; border-radius:5px;
        height:100%;}
        .overlay
        {
            background:black;
            position:absolute;
            
        }
.package-content h4{
    font-size: 24px;
    color: var(---primecolor);
    font-family: 'Playball', cursive;
    font-weight: 400;
    padding-bottom: 5px;
}
.package-content h2{
    font-size: 25px;
    color:var(---primecolor);
    font-weight: 600;
    font-family:var(---headFont);
    line-height:80px;
}
.package-content h3{
    font-size: 36px;
    color:var(---primecolor);
    font-family: 'Playball', cursive;
    font-weight: 400;
    margin-bottom: 22px;
}
.two .package-content{
    padding-left: 70px;
}

.event-img{position: relative;
height:250px;
width:100%;}
.event-img img{position: relative;
    width:100%;
    height:100%;}
.event-img a:after{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(212, 167, 98, 0.5);
    content: "";
    opacity: 0;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}
.single-event
{
    padding:20px 0;
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
}
.single-event h4{
    position:absolute;
    text-decoration: none;
    /* border:1px solid #fff; */
    padding:10px 10px;
    font-size: 18px;
    background:rgba(239, 18, 18, 0.56);
    /* background:var(---primecolor); */
    color: #fff;
    width:80%;
    height:16%;
    font-family:var(---btnFont);
    /* font-weight: 600; */
    top:35%;
    left:10%;
    bottom:0px;
    right:0;
   
    border-radius:5px;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}
.single-event h4{ margin-top: 17px; font-size:20px; font-weight:500;}
.single-event:hover h4 a{color: #D4A762}
@media(max-width:900px)
{
    .single-event h4{
        position:absolute;
        text-decoration: none;
        padding:10px 10px;
        font-size: 14px;
        background:rgba(239, 18, 18, 0.56);
        color: #fff;
        width:50%;
        height:16%;
        font-family:var(---btnFont);
        top:35%;
        left:20%;
        bottom:0px;
        right:0;
       
        border-radius:5px;
        -webkit-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
    }
}
@media(max-width:500px)
{
    .two .package-content{
        padding-left: 10px;
        padding-top:20px;
    }
    .package-content h2{
        font-size: 20px;
        color:var(---primecolor);
        font-weight: 600;
        font-family:var(---headFont);
        line-height:30px;
    }
    .package-content h3{
        font-size: 20px;
        color: var(---primecolor);
        font-family: 'Playball', cursive;
        font-weight: 400; 
        margin-bottom: 22px;
    }
}