.banqImg
{
    height:300px;
margin:0 10px;
}
.banqImg img
{ width:100%;
    height:100%;
    
}
.mahaImg
{
    height:350px;
   
   /* width:350px;   */
}
.mahaImg img
{
    height:100%;
   width:100%;  
}
@media(max-width:500px)
{
    .banqImg
{
    height:280px;

}
.banqImg img
{ width:100%;
    height:100%;
    
}
}
@media(max-width:500px)
{
    .mahaImg
{
    height:280px;

}
.mahaImg img
{ width:100%;
    height:100%;
    
}
}