.Tabs-container {
    margin: 60px 0px 50px 0px;
    /* background-color: #eee; */
    border:1px solid #eee;
    border-radius:5px;
    overflow-x:auto;
    /* padding: 10px 0px; */
    /* border-radius:50px; */
}

.ButtonG {
    border: none;
    color:#000;
    font-weight: 600;
    
    font-size:18px;
    font-family: var(---headFont);
}

.btn:hover{
    color:peru !important;
border-bottom:1px solid #000 !important;
transition:.3s ease-in-out;
}
@media(max-width:500px)
{
    .Tabs-container {
        margin: 20px 0px 10px 0px;
        background-color: #eee;
        border:1px solid var(---primaryfont);
        padding: 10px 0px;
        border-radius:50px;
    }
}
.swiper1 .active{
	color:#ec5566;
	border-bottom:2px solid #ec5566;
  }
  /* .swiper-slide
  {
    margin-right:10px;
    width:20px;
  } */